@font-face {
  font-display: block;
  font-family: AvenirNextLTProDemi;
  src: url('./AvenirNextLTPro-Demi.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: AvenirNextLTProRegular;
  src: url('./AvenirNextLTPro-Regular.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: BoSPillGothic;
  src: url('./bospillgothicbold.eot') format('embedded-opentype'),
    url('./bospillgothicbold.woff') format('woff');
}
