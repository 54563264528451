@font-face {
  font-display: block;
  font-family: SourceSansPro-SemiBold;
  src: url('./SourceSansPro-SemiBold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-display: block;
  font-family: SourceSansPro-Regular;
  src: url('./SourceSansPro-Regular.woff') format('woff');
}
