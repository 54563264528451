@font-face {
  font-display: block;
  font-family: agendaBold;
  src: url('./agendaBold.eot') format('embedded-opentype'),
    url('./agendaBold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-display: block;
  font-family: agendaNormal;
  src: url('./agendaNormal.eot') format('embedded-opentype'),
    url('./agendaNormal.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: helveticaNeueCondensedBlack;
  src: url('./helveticaNeueCondensedBlack.eot') format('embedded-opentype'),
    url('./helveticaNeueCondensedBlack.woff') format('woff');
}
