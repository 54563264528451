@font-face {
  font-display: block;
  font-family: lloydsBankJackLight;
  src: url('./lloydsBankJackLight.eot') format('embedded-opentype'),
    url('./lloydsBankJackLight.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-display: block;
  font-family: lloydsBankJackMedium;
  src: url('./lloydsBankJackMedium.eot') format('embedded-opentype'),
    url('./lloydsBankJackMedium.woff') format('woff');
}
