@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-LightItalic";
  src: url("./UnitRoundedW01-LightItalic.eot?#iefix");
  src: url("./UnitRoundedW01-LightItalic.eot?#iefix") format("eot"), url("./UnitRoundedW01-LightItalic.woff2") format("woff2"), url("./UnitRoundedW01-LightItalic.woff") format("woff"), url("./UnitRoundedW01-LightItalic.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Bold";
  src: url("./UnitRoundedW01-Bold.eot?#iefix");
  src: url("./UnitRoundedW01-Bold.eot?#iefix") format("eot"), url("./UnitRoundedW01-Bold.woff2") format("woff2"), url("./UnitRoundedW01-Bold.woff") format("woff"), url("./UnitRoundedW01-Bold.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Medium";
  src: url("./UnitRoundedW01-Medium.eot?#iefix");
  src: url("./UnitRoundedW01-Medium.eot?#iefix") format("eot"), url("./UnitRoundedW01-Medium.woff2") format("woff2"), url("./UnitRoundedW01-Medium.woff") format("woff"), url("./UnitRoundedW01-Medium.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Regular";
  src: url("./UnitRoundedW01-Regular.eot?#iefix");
  src: url("./UnitRoundedW01-Regular.eot?#iefix") format("eot"), url("./UnitRoundedW01-Regular.woff2") format("woff2"), url("./UnitRoundedW01-Regular.woff") format("woff"), url("./UnitRoundedW01-Regular.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Light";
  src: url("./UnitRoundedW01-Light.eot?#iefix");
  src: url("./UnitRoundedW01-Light.eot?#iefix") format("eot"), url("./UnitRoundedW01-Light.woff2") format("woff2"), url("./UnitRoundedW01-Light.woff") format("woff"), url("./UnitRoundedW01-Light.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Black";
  src: url("./UnitRoundedW01-Black.eot?#iefix");
  src: url("./UnitRoundedW01-Black.eot?#iefix") format("eot"), url("./UnitRoundedW01-Black.woff2") format("woff2"), url("./UnitRoundedW01-Black.woff") format("woff"), url("./UnitRoundedW01-Black.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "UnitRoundedW01-Ultra";
  src: url("./UnitRoundedW01-Ultra.eot?#iefix");
  src: url("./UnitRoundedW01-Ultra.eot?#iefix") format("eot"), url("./UnitRoundedW01-Ultra.woff2") format("woff2"), url("./UnitRoundedW01-Ultra.woff") format("woff"), url("./UnitRoundedW01-Ultra.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "Arial-Robot-SFPro";
  src: url("./Arial.ttf") format("truetype");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-BlackItalic-Web";
  src: url("./GiorgioSans-BlackItalic-Web.woff2") format("woff2"), url("./GiorgioSans-BlackItalic-Web.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-Extralight-Web";
  src: url("./GiorgioSans-Extralight-Web.woff2") format("woff2"), url("./GiorgioSans-Extralight-Web.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-Heavy-Web";
  src: url("./GiorgioSans-Heavy-Web.woff2") format("woff2"), url("./GiorgioSans-Heavy-Web.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-Heavy-Web";
  src: url("./GiorgioSans-Heavy-Web.woff2") format("woff2"), url("./GiorgioSans-Heavy-Web.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-Light-Web";
  src: url("./GiorgioSans-Light-Web.woff2") format("woff2"), url("./GiorgioSans-Light-Web.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "GiorgioSans-Regular-Web";
  src: url("./GiorgioSans-Regular-Web.woff2") format("woff2"), url("./GiorgioSans-Regular-Web.woff") format("woff");
}